import React from "react"
import styled from "styled-components"

import locationImg from "../images/location.svg"
import emailImg from "../images/mail.svg"
import callImg from "../images/call.svg"
import InstaImg from "../images/instagram-logo.svg"
import FacebookImg from "../images/facebook.svg"
import TwitterImg from "../images/twitter.svg"

const FooterGroup = styled.div`
  background: #0177a2;
  padding-bottom: 2%;
`
const FooterHeader = styled.h4`
  margin-bottom: 20px;
  color: white;
  text-align: center;
  font-weight: 600;
  opacity: 0.9;
`

const FooterSocial = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  text-align: center;
  justify-items: center;
`

const FooterContact = styled.p`
  display: grid;
  grid-template-columns: 30px auto;
  grid-template-rows: auto;
  color: white;
  opacity: 0.95;
  font-size: 15px;
  font-weight: 500;
  min-height: 25px;
`
const FooterContent = styled.p`
  list-style: none;
  color: white;
  min-height: 25px;
  opacity: 0.95;
  font-size: 15px;
  font-weight: 500;
  align-self: center;
  justify-content: center;
  margin-bottom: auto;
`

const FooterAbout = styled.p`
  justify-content: left;
  align-content: center;
  color: white;
  opacity: 0.95;
  font-size: 15px;
  font-weight: 500;
  bottom: 0;
  left: 0;
`

const FooterInstagram = styled.div`
  background-image: url(${InstaImg});
  background-size: 24px;
  width: 24px;
  min-height: 24px;
  background-repeat: no-repeat;
  margin: auto !important;
  justify-self: center;
  align-self: center;
`
const FooterFb = styled.div`
  background-image: url(${FacebookImg});
  background-size: 24px;
  width: 24px;
  min-height: 24px;
  background-repeat: no-repeat;
  margin: auto !important;
  justify-self: center;
  align-self: center;
`
const FooterTwitter = styled.div`
  background-image: url(${TwitterImg});
  background-size: 24px;
  width: 24px;
  min-height: 24px;
  background-repeat: no-repeat;
  margin: auto !important;
  justify-self: center;
  align-self: center;
`

const FooterEmailImage = styled.div`
  background-image: url(${emailImg});
  background-size: 14px;
  width: 14px;
  min-height: 14px;
  background-repeat: no-repeat;
  margin: auto !important;
  justify-self: center;
  align-self: center;
`

const FooterCallImage = styled.div`
  background-image: url(${callImg});
  background-size: 14px;
  width: 14px;
  min-height: 14px;
  background-repeat: no-repeat;
  margin: auto !important;
`

const FooterLocationImage = styled.div`
  background-image: url(${locationImg});
  background-size: 14px;
  width: 14px;
  min-height: 14px;
  background-repeat: no-repeat;
  margin: auto !important;
`

const Contact = () => (
  <FooterGroup className="page-footer font-small pt-4">
    <div className="container-fluid text-center text-md-left">
      <div class="row ml-5 mr-5">
        <div class="col-xs-12 col-sm-6 mt-md-0 mt-3">
          <FooterHeader>About Us:</FooterHeader>
          <FooterAbout>
            QriousCo. was established in 2018 to carter the growing needs in the
            space of technical expertise with special focus on Mobile App
            Development and Website development.
            <br />
            <br />
            QriousCo. is dedicated to provide the high quality of services to
            its client and has been consistent with its services quality since
            its establishment, which has been the key factor for its growing
            success around the globe.
          </FooterAbout>
        </div>
        <div class=" col-xs-12 col-sm-3 mb-md-0 mb-3">
          <FooterHeader>Contact:</FooterHeader>
          <ul class="list-unstyled">
            <li>
              <FooterContact>
                <FooterLocationImage />
                <FooterContent>Location: Baneshwor, Kathmandu</FooterContent>
              </FooterContact>
            </li>
            <li>
              <FooterContact>
                <FooterEmailImage />
                Email: info.qriousco@gmail.com
              </FooterContact>
            </li>
            <li>
              <FooterContact>
                <FooterCallImage />
                Phone: +977 980-8770386
              </FooterContact>
            </li>
          </ul>
        </div>
        <div class="col-xs-12 col-sm-3 mb-md-0 mb-3">
          <FooterHeader>Social:</FooterHeader>
          <FooterSocial>
            <a href="https://www.facebook.com/QriousCo/">
              <FooterFb />
            </a>
            <a href="https://www.instagram.com/qriousco/?hl=en">
              <FooterInstagram />
            </a>

            <a href="https://twitter.com/QriousCo">
              <FooterTwitter />
            </a>
          </FooterSocial>
        </div>
      </div>
    </div>
  </FooterGroup>
)
export default Contact
