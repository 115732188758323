import React from "react"
import styled from "styled-components"

const CardGroup = styled.div`
  position: relative;
  overflow: hidden;
  text-align: center;
  display: grid;
  grid-column: 1fr 1fr;
  margin: 45px;
`

const CardImage = styled.div`
  width: 50px;
  height: 50px;
  text-align: center;
  margin: auto;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${props => props.image});
`

const CardTitle = styled.h3`
  float: right;
  color: black;
  margin-top: 2%;
  font-size: 22px;
  font-weight: 600;
`

const CardDescription = styled.p`
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
  margin-top: 2%;
  justify-self: center;
  max-width: 500px;
  font-size: 16px;
`

const Card = props => (
  <CardGroup>
    <CardImage image={props.image} />
    <CardTitle>{props.title}</CardTitle>
    <CardDescription>{props.text}</CardDescription>
  </CardGroup>
)

export default Card
