/*global FB*/
import React from "react"
import Wave from "../components/Wave"
import "bootstrap/dist/css/bootstrap.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AppComponent from "../components/AppComponent"
import Card from "../components/Card"
import Cell from "../components/Cell"
import Contact from "../components/Contact"
import styled from "styled-components"
import bg from "../images/footer-blue.png"
import ReactGA from 'react-ga';

const UpperFooterContainer = styled.div`
  background: url(${bg});
  height: 347px;
`

class IndexPage extends React.Component {
  componentDidMount() {
    ReactGA.initialize('UA-148053079-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    
    //if (typeof FB != "undefined" && FB != null) {
    window.fbAsyncInit = function() {
      FB.init({
        xfbml: true,
        version: "v3.2",
      })
    }
    ;(function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js"
      fjs.parentNode.insertBefore(js, fjs)
    })(document, "script", "facebook-jssdk")
    //}
  }
  render() {
    return (
      <Layout>
        <SEO title="Home" />
        <div className="Hero">
          <div className="HeroGroup">
            <h1>QriousCo.</h1>
            <br />
            <h2>
              Driven by Curiousty & <br />
              Always Curious
            </h2>
            <Wave />
          </div>
        </div>
        {/* <div className="row">
      <div className="col-xs-12 col-sm-6 Hero HeroGroup ">
        <div className="col-xs-12 col-sm-6 text-center">
          <h1>QriousCo.</h1>
          <br />
          <h2>
            Always Curious... <br />& Driven by Curiousty...
          </h2>
        </div>
        <Wave />
      </div>
    </div> */}
        <div id="services">
          <h2 className="CardGroupTitle">Our Services</h2>
        </div>
        <h2 className="CardGroupDescription">
          A top-notch services with high
          <br />
          level code architecture & testing.
        </h2>
        <AppComponent
          image={require("../images/mobile-app.svg")}
          title="Apps Development"
          description="Our creative mobile application team have extensive experience to
          develop engaging and ease of use Android and iOS apps that can enhance
          your user experience and grow your business."
        />

        <AppComponent
          image={require("../images/web-development.svg")}
          title="Web Development"
          description="Our highly experienced team delivers larger, robust, scalable and
          responsive web application that are aligned to your strategy, time
          and budget and grow your everyday business to the next level."
        />
        <AppComponent
          image={require("../images/share.svg")}
          title="Facebook Boosting"
          description="Want to expand your business to more audiences? Boost Facebook posts
          and offers with us in minimal prices and see your audiences grow."
        />
        <div id="about">
          <h2 className="CardGroupTitle">How we work?</h2>
        </div>
        <h2 className="CardGroupDescription">
          We’ve got everything you need to
          <br />
          launch & grow your business.
        </h2>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <Card
              image={require("../images/project.png")}
              title="Requirement Analysis"
              text="Requirements document builds a bridge between two different islands - customers/consumers and software development team. At QriousCo., we carefully analyse the requirements of our customer to design a great product."
            />
          </div>
          <div className="col-xs-12 col-sm-6">
            <Card
              image={require("../images/prototype.png")}
              title="Prototype Development"
              text="At QriousCo., we develop a prototype of the website or app(commonly know as Proof of Concept) and show it to our customer so that we are both in sync. This helps us make the end product as required."
            />
          </div>
          <div className="col-xs-12 col-sm-6">
            <Card
              image={require("../images/design.png")}
              title="Design"
              text="Design is the crux of any software development. Be it a website or a mobile app, We design emotional, simple and intelligent brands for those brave enough to want more. We have dedicated team for design and prototyping"
            />
          </div>
          <div className="col-xs-12 col-sm-6">
            <Card
              image={require("../images/development.png")}
              title="Development"
              text="At QriousCo., we provide a high quality code that is maintainable and easy to understand. We emphasize on testability of the code that ensures the easy and early detection of bugs, thereby saves both time and cost."
            />
          </div>
          <div className="col-xs-12 col-sm-6">
            <Card
              image={require("../images/quality.png")}
              title="Quality Assurance"
              text="Quality assurance is the integral part of software development. At QriousCo., QA team helps smooth building of a product by preventing mistakes and defects in manufactured products and avoiding problems when delivering solutions or services to customers."
            />
          </div>
          <div className="col-xs-12 col-sm-6">
            <Card
              image={require("../images/prototype.png")}
              title="Support & Maintenance"
              text="Customer service means a permanent support to the client. We offer a variety of maintenance modalities that can always be adapted. At QriuosCo., we provide long term maintenance for the software build in house as well as for products built outside."
            />
          </div>
        </div>
        <div id="why-us">
          <h2 className="CardGroupTitle">Why choose us?</h2>
        </div>
        <h2 className="CardGroupDescription">
          We take pride in the solutions we deliver
          <br />& bring your project into existence.
        </h2>
        <div className="row SectionCellGroup">
          <div className="col-xs-12 col-sm-6">
            <Cell title="On-time and dedicated" />
          </div>
          <div className="col-xs-12 col-sm-6">
            <Cell title="We follow the principles of Agility" />
          </div>
          <div className="col-xs-12 col-sm-6">
            <Cell title="Team of expert developers" />
          </div>
          <div className="col-xs-12 col-sm-6">
            <Cell title="Deliver unmatched services" />
          </div>
          <div className="col-xs-12 col-sm-6">
            <Cell title="Always dedicated to client needs and requirements" />
          </div>
          <div className="col-xs-12 col-sm-6">
            <Cell title="Provide leading & latest technology" />
          </div>
        </div>
        <UpperFooterContainer />
        <div id="contact">
          <Contact />
        </div>
        <div
          className="fb-customerchat"
          attribution="setup_tool"
          page_id="933521956843030"
        />
      </Layout>
    )
  }
}

export default IndexPage
