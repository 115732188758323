import React from "react"
import styled from "styled-components"

const Group = styled.div`
  width: 100%;
  margin: 5% 0;
`
const Image = styled.img`
  width: 100%;
  height: 210px;
  object-fit: contain;
  display: block;
  margin: 10% auto 0% auto;
  @media (max-width: 640px) {
    height: 160px;
  }
`

const Info = styled.div`
  overflow: hidden;
  text-align: start;
  margin: 10% 5% 0 0;
  @media (max-width: 640px) {
    text-align: center;
  }
`

const Title = styled.h4`
  font-size: 34px;
  font-weight: 600;
  background: linear-gradient(104deg, #050a27 0%, #4a548c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  margin: 0;
  -webkit-text-fill-color: transparent;
  @media (max-width: 640px) {
    font-size: 28px;
  }
`

const Description = styled.p`
  color: rgb(0, 0, 0, 0.7);
  font-size: 16px;
  font-weight: 500;
  max-width: 450px;
  text-align: left;
  margin-top: 1%;
  @media (max-width: 640px) {
    text-align: center;
    margin-left: 5%;
  }
`

const AppComponent = props => (
  <Group>
    <div className="row">
      <div className="col-xs-12 col-sm-6 justify-content-end">
        <Image classname="image-responsive" src={props.image} />
      </div>
      <div className="col-xs-12 col-sm-6">
        <Info>
          <Title>{props.title}</Title>
          <Description>{props.description}</Description>
        </Info>
      </div>
    </div>
  </Group>
)

export default AppComponent
