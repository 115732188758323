import React from "react"
import styled from "styled-components"
import svgImage from "../images/checked.svg"

const CellGroup = styled.div`
  display: grid;
  grid-template-columns: 35px auto;
  grid-gap: 25px;
  align-items: center;
  margin-bottom: 35px;
`

const CellImage = styled.div`
  width: 25px;
  height: 25px;
  background: white;
  background-image: url(${svgImage});
  background-size: 25px;
  @media (max-width: 640px) {
    width: 20px;
    height: 20px;
    background-size: 20px;
  }
`

const CellTitle = styled.p`
  font-size: 17px;
  font-weight: 500;
  float: left;
  opacity: 0.95;
  margin: 0;
  @media (max-width: 640px) {
    font-size: 15px;
  }
`

const Cell = props => (
  <CellGroup>
    <CellImage />
    <CellTitle>{props.title}</CellTitle>
  </CellGroup>
)

export default Cell
